const streamURL = "https://stream.abigail.fm";
let audio;

const main = () => {
  // Setup audio player:
  audio = new Audio(streamURL);
  audio.muted = true;
  audio.controls = true;
  audio.preload = true;
  
  // Setup UI:
  const trigger = document.querySelector("#mute-toggle-button");
  
  const configureUI = () => {
    const isUnmuted = trigger.classList.contains("unmuted");
    
    trigger.innerHTML = `
    <span class="icon">
      ${ !isUnmuted ? `<svg width="24" height="26" aria-hidden="true">
          <use xlink:href="#play"></use>
        </svg>` : 
        `<svg width="26" height="26" aria-hidden="true">
          <use xlink:href="#stop"></use>
        </svg>`}
    </span>
    <span class="label">${isUnmuted ? "Stop" : "Play"}</span>
    `;
  };
  configureUI();
  
  trigger.addEventListener("click", () => {
    const isUnmuted = trigger.classList.toggle("unmuted");
    
    try {
      audio.muted = !isUnmuted;	
      audio.play();
    } catch (e) {
      console.warn("Encountered issue when attempting to toggle state", e);	
    }
    
    configureUI();
  });
  
  // Decoration:
  const prepareSeparators = () => {
    const dottedElements = document.querySelectorAll(".separator");
    
    let repeatedText = Array(80).fill(".").join("");
    
    for (const element of dottedElements) {
      const span = document.createElement("span");
      span.className = "dots";
      span.textContent = repeatedText;
      
      element.appendChild(span);
    }
  };
  
  prepareSeparators();
};

document.addEventListener("DOMContentLoaded", main);
